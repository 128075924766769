<template>
  <div class="dashboard-container">
    <div class="app-container" style="margin-bottom: 20px">
      <el-row>
        <el-col :span="24" align="right">
          <el-button class="el-icon-edit" type="primary" @click="add"
          
            >保存联系方式</el-button
          >
        </el-col>
      </el-row>
    </div>


    <el-dialog
      title="保存联系方式"
      :visible.sync="showDialog"
      width="60%"
      @close="btnCancel"
    >
      <el-form :model="list" :rules="rules" ref="perForm" label-width="200px">
        <el-form-item label="公众号二维码">
          <el-upload
            ref="upload"
            action="http://up-z2.qiniup.com"
            :data="postData"
            :show-file-list="false"
            accept="image/jpeg, image/gif, image/png, image/bmp"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <div v-if="dialogImageUrl" class="avatar">
              <img :src="dialogImageUrl" />
            </div>

            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="联系人" prop="linkMan">
          <el-input v-model="list.linkMan" style="width: 50%"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="tel">
          <el-input v-model="list.tel" style="width: 50%"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="phone">
          <el-input v-model="list.phone" style="width: 50%"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="list.email" style="width: 50%"></el-input>
        </el-form-item>
        <el-form-item label="公司地址">
          <el-input v-model="list.address" style="width: 50%"></el-input>
        </el-form-item>
        <el-form-item label="微信">
          <el-input v-model="list.wxId" style="width: 50%"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="btnCancel">取 消</el-button>
        <el-button type="primary" @click="enter">确 定</el-button>
      </span>
    </el-dialog>
    <div class="tab">
      <el-table
        :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
        :data="activeData"
        border
        style="width: 100%"
        ref="activeData"
        class="emp_table"
        v-if="activeData"
      >
        <el-table-column
          align="center"
          prop="wxCode"
          label="公众号二维码"
          width="200"
        >
          <template slot-scope="scope">
            <div class="permitImg-img">
              <el-image
                style="width: 35%"
                :src="'http://img.c-fol.cn/' + scope.row.wxCode"
                :preview-src-list="['http://img.c-fol.cn/' + scope.row.wxCode]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="linkMan"
          label="联系人"
          :show-overflow-tooltip="true"
        >
     
        </el-table-column>

        <el-table-column
          align="center"
          prop="phone"
          label="手机"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
         <el-table-column
          align="center"
          prop="tel"
          label="电话"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="address"
          label="公司地址"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="wxId"
          label="微信号"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="email"
          label="邮箱"
          :show-overflow-tooltip="true"
        >
        </el-table-column>

        <el-table-column align="center" label="操作" width="251px">
          <template slot-scope="scope">
            <!-- <el-button
              type="text"
              size="small"
              @click="updateSchoolInfo(scope.row)"
            >
              编辑
            </el-button> -->
            <el-button
              type="text"
              size="small"
              @click="delCommonMsg(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <!-- <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.pageSize"
        @pagination="getCommonMsgList"
      /> -->
    </div>
  </div>
</template>

<script>

import {
  getContactMsg,
  getUploadToken,
  saveContactMsg,
  clearContactMsg,
} from "../../request/http";
export default {

  data() {
    return {
      addorredit: false,
      // 表格配置

      // 表格数据
      activeData: [],
      showDialog: false,
      list: {
        linkMan: "",
        phone: "",
        email: "",
        address: "",
        wxId: "",
        wxCode: "",
        tel:""
      },
      dialogImageUrl: "",
      postData: {
        token: "",
        key: "",
      },
      rules: {
        linkMan: [
          { required: true, message: "联系人不能为空", trigger: "blur" },
        ],
        phone: [
         {
            pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: '请输入正确的手机号码'
          }],
           tel: [{ required: true, message: "电话不能为空", trigger: "blur" },
         {
            pattern: /^0\d{2,3}-?\d{7,8}$/,
            message: '请输入正确的电话号码'
          }],
      },
    };
  },
  created() {
    this.show();
    console.log(this.activeData.length);
  },
  methods: {
    updateSchoolInfo(row){
   console.log(row);
     this.showDialog = true
   this.dialogImageUrl ="http://img.c-fol.cn/" +row.wxCode
     this.list={
        linkMan: row.linkMan,
        phone: row.phone,
        tel:row.tel,
        email:row.email,
        address: row.address,
        wxId: row.wxId,
        wxCode: row.wxCode,
      }
    },
    btnCancel() {
 this.dialogImageUrl=''
      this.list = {};
      this.$refs.perForm.resetFields();
      this.showDialog = false;
    },
     delCommonMsg(row){
   clearContactMsg().then ((res) => {
                    if  (res.code == 0) {
                      this.$message   ({
                        message: "删除成功",
                        type: "success",
                      });
                       this.show()
                    }
                  });
        
                 
    },
    
    
    add() {
      this.showDialog = true;
      console.log(this.activeData[0]);
       
      if(this.activeData[0]){
        let row =this.activeData[0]
        if(row.wxCode){
          this.dialogImageUrl ="http://img.c-fol.cn/" +row.wxCode
        }
        
     this.list={
        linkMan: row.linkMan,
        phone: row.phone,
        tel:row.tel,
        email:row.email,
        address: row.address,
        wxId: row.wxId,
        wxCode: row.wxCode,
      }
      }
      // else if (this.activeData.length>=1) {
      //   this.$message({
      //     message: "最多添加一条",
      //     type: "warning",
      //   });
      // }
    },
    show() {
      getContactMsg().then((res) => {
        console.log(res);
        if(res.data){
this.activeData = Array(res.data);
        }
        else {
          this.activeData=[]
        }
        
      });
      console.log( this.activeData);
    },
   
    enter() {
    
        saveContactMsg(this.list).then((res) => {
          if (res.code == 0)
            this.$message({
              message: "保存成功",
              type: "success",
            });
          this.showDialog = false;
          this.show();
        });
     
    
      
  
    },

    handleAvatarSuccess(res, file) {
      this.dialogImageUrl = "http://img.c-fol.cn/" + res.key;
      this.list.wxCode = res.key;
    },
    async beforeAvatarUpload(file) {
      // console.log("上传前");
      let { data } = await getUploadToken(); // console.log(data);
      if (data == "" || data == null) {
        this.message.error("上传图片时获取Token失败！");
        return;
      }
      this.postData.token = data.token;
      this.postData.key = data.fid;
    },
  },
};
</script>

<style >
.el-input {
  /* width: 200px; */
}

.avatar > img {
  width: 200px;
  height: 200px;
}
</style>